.pro-sidebar > .pro-sidebar-inner {
  background-color: inherit;
}

.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 16px;
  font-family: Inter-Regular, Inter;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background-color: inherit;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  background-color: inherit;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 20%;
}

.pro-sidebar .pro-menu a {
  color: white;
}

.pro-sidebar .pro-menu a:hover {
  color: #4DD4FE;
}

.sidebar-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-btn {
  transition: width 0.3s;
  width: 150px;
  padding: 1px 15px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.05);
  color: #adadad;
  text-decoration: none;
  margin: 0 auto;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sidebar-btn:hover {
  color: red;
}

.sidebar-btn > span {
  margin-left: 5px;
  font-size: 13px;
}